html,body {
  height: 100%;
}

#root {
  height: 100%;
}

.app {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  /*
    vhは「Webページとしての高さ」ではなく
    ブラウザの高さなので、
    スマートフォンのアドレスバーなどがあると、その分高さがはみ出てスクロールバーが出てしまう。
    これはpcのブラウザの開発ツールでスマートフォンでの表示を確かめているときには気づかないので注意
    参照 https://qiita.com/fruitriin/items/71c0cb9aeca28882bdc4
   */
  height: 100%; /* 上記記事で紹介されているテクニック */
}

.body {
  border: solid;
  border-radius: 10px;
  padding: 5px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 50%;
  /* white-space: nowrap; */
}

.horizontal-icon {
  width: 50px;
  transform: scaleY(4);
  cursor: pointer;
}

.vertical-icon {
  width: 50px;
  transform: scaleX(4);
  margin-top: 20px;
  margin-bottom: 20px;
  cursor: pointer;
}

.horizontal-icon:hover {
  background-color: lightgray;
}

.vertical-icon:hover {
  background-color: lightgray;
}